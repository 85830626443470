"use client";

import Image from "next/image";
import visagiste from "@/assets/images/prestations/visagiste.jpg";
import colorationVegetale from "@/assets/images/prestations/coloration-vegetale.webp";
import soinbalinet from "@/assets/images/prestations/soin-balinet.jpg";
import privatisationlieu from "@/assets/images/prestations/privatisation-lieu.jpg";
import Link from "next/link";
import { MyNauiLongArrowRight } from "@/assets/icons/mynaui-long-arrow-right";

export const PrestationsSection = () => {
  return (
    <section
      className="max-w-8xl flex w-full flex-col gap-[3rem] px-4 py-20 ph:px-24 md:gap-0 md:px-48 lg:px-72 xl:px-96"
      id="prestations"
    >
      <div className="flex w-full flex-col justify-between md:flex-row">
        <h2 className="mb-2 font-cormorant text-4xl text-primary ph:text-5xl sm:text-6xl md:mb-12 lg:mb-16 lg:text-7xl">
          PRESTATIONS
        </h2>
        <Link
          href="/prestations"
          className="inline-flex flex-row items-center gap-2 text-sm text-primary transition-colors hover:text-gold xs:ml-1 ph:text-base md:mb-5 md:ml-0 lg:mb-7 xl:mb-8"
        >
          Voir toutes les prestations
          <MyNauiLongArrowRight className="h-4 w-4 ph:h-6 ph:w-6" />
        </Link>
      </div>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {/* Left column */}
        <div className="flex flex-col gap-4">
          <div className="group relative h-[400px] md:h-[300px]">
            <Link href="/prestations/soin-balinet">
              <Image
                src={soinbalinet}
                alt="Soin balinet"
                fill
                sizes="(max-width: 768px) 100vw, 50vw"
                className="object-cover transition-all duration-300 group-hover:grayscale"
              />
              <div className="absolute inset-0 flex items-end bg-black/20">
                <h3 className="p-6 text-xl text-white md:text-2xl">
                  SOIN BALINET
                </h3>
              </div>
            </Link>
          </div>

          <div className="group relative h-[400px] md:h-[300px]">
            <Link href="/prestations/visagiste">
              <Image
                src={visagiste}
                alt="Visagiste"
                fill
                sizes="(max-width: 768px) 100vw, 50vw"
                className="object-cover transition-all duration-300 group-hover:grayscale"
              />
              <div className="absolute inset-0 flex items-end bg-black/20">
                <h3 className="p-6 text-xl text-white md:text-2xl">
                  VISAGISTE
                </h3>
              </div>
            </Link>
          </div>
        </div>

        {/* Right column */}
        <div className="flex flex-col gap-4">
          <div className="group relative h-[400px] md:h-[300px]">
            <Link href="/prestations/coloration-vegetale">
              <Image
                src={colorationVegetale}
                alt="Coloration végétale"
                fill
                sizes="(max-width: 768px) 100vw, 50vw"
                className="object-cover transition-all duration-300 group-hover:grayscale"
              />
              <div className="absolute inset-0 flex items-end bg-black/20">
                <h3 className="p-6 text-xl text-white md:text-2xl">
                  COLORATION VÉGÉTALE
                </h3>
              </div>
            </Link>
          </div>

          <div className="group relative h-[400px] md:h-[300px]">
            <Link href="/prestations/privatisation-lieu">
              <Image
                src={privatisationlieu}
                alt="Privatisation de lieu"
                fill
                sizes="(max-width: 768px) 100vw, 50vw"
                className="object-cover transition-all duration-300 group-hover:grayscale"
              />
              <div className="absolute inset-0 flex items-end bg-black/20">
                <h3 className="p-6 text-xl text-white md:text-2xl">
                  PRIVATISATION DE LIEU
                </h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
