import Link from "next/link";
import { Icon } from "@iconify/react";

export const ContactCTA = () => {
  const socialLinks = [
    {
      name: "Instagram",
      icon: "formkit:instagram",
      href: "https://www.instagram.com/salonsalexandragrey/",
    },
    {
      name: "Facebook",
      icon: "formkit:facebook",
      href: "https://www.facebook.com/SalonsAlexandraGrey",
    },
    {
      name: "Linkedin",
      icon: "formkit:linkedin",
      href: "https://www.linkedin.com/company/alexandra-grey",
    },
  ];

  return (
    <div className="flex h-auto w-full flex-col items-center justify-center gap-6 bg-[#191919] py-8">
      <div className="flex flex-col items-center gap-6">
        {/* <span className="text-center text-sm text-white/80">
          Nous contacter ou réserver un rendez-vous
        </span> */}

        <div className="flex gap-4">
          {socialLinks.map((social) => (
            <Link
              key={social.name}
              href={social.href}
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-full bg-white p-2 transition-colors hover:bg-gray-200"
              aria-label={social.name}
            >
              <Icon icon={social.icon} className="h-5 w-5 text-[#191919]" />
            </Link>
          ))}
        </div>

        <div className="flex items-center justify-center gap-8">
          <Link
            href="/contact"
            className="group relative inline-block pb-[2px] text-white"
          >
            <span>Nous contacter</span>
            <div className="absolute bottom-0 left-0 h-[1px] w-full bg-white">
              <div className="h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></div>
            </div>
          </Link>

          <Link
            href="/reservation"
            className="group relative inline-block pb-[2px] text-white"
          >
            <span>Prendre rendez-vous</span>
            <div className="absolute bottom-0 left-0 h-[1px] w-full bg-white">
              <div className="h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
