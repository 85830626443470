"use client";

import { useState, useEffect } from "react";
import { HeroSection } from "@/(landing-page)/sections/HeroSection";
import { PrestationsSection } from "@/(landing-page)/sections/PrestationsSection";
import { CollectionsSection } from "@/(landing-page)/sections/CollectionsSection";
import { MonthlyEventSection } from "@/(landing-page)/sections/MonthlyEventSection";
import { ReviewsSection } from "@/components/sections/reviews/ReviewsSection";
import { FindUsSection } from "@/components/sections/find-us/FindUsSection";
import { OurSalonsHours } from "@/components/sections/nos-salons-hours/OurSalonsHours";
import { GiftCardSection } from "@/components/sections/GiftCardSection";
import { SeoSection } from "@/components/sections/SeoSection";
import { ContactCTA } from "@/components/sections/Contact-CTA";
import { OurSalonsImages } from "@/components/sections/nos-salons-images/OurSalonsImages";
import { EventModal } from "@/components/common/modals/EventModal";

export default function HomePage() {
  const [showEventModal, setShowEventModal] = useState(false);

  useEffect(() => {
    // Vérifier si nous sommes côté client avant d'utiliser le storage
    if (typeof window !== "undefined") {
      setShowEventModal(true);
    }
  }, []);

  return (
    <main className="flex min-h-screen max-w-[100vw] flex-col items-center justify-between">
      {showEventModal && (
        <EventModal onClose={() => setShowEventModal(false)} />
      )}
      <HeroSection />
      <OurSalonsImages />
      <MonthlyEventSection />
      <PrestationsSection />
      <GiftCardSection />
      <CollectionsSection />
      <ContactCTA />
      <ReviewsSection />
      <FindUsSection />
      <OurSalonsHours />
      <SeoSection />
    </main>
  );
}
