"use client";

import { useEffect, useState, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import { motion, AnimatePresence } from "framer-motion";
import { formatSalonUrl } from "@/utils/formatSalonUrl";
import { PromoCodeModal } from "@/components/sections/monthly-event/PromoCodeModal";
import {
  getCurrentEvents,
  formatEventPeriod,
} from "@/(global)/evenements/data/events";

interface EventModalProps {
  onClose: () => void;
}

export const EventModal = ({ onClose }: EventModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPromoModalOpen, setIsPromoModalOpen] = useState(false);
  const [currentEvents, setCurrentEvents] = useState<any[]>([]);
  const [selectedEventForPromo, setSelectedEventForPromo] = useState<any>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [activeEventIndex, setActiveEventIndex] = useState(0);

  // Référence pour le timer de défilement automatique
  const slideTimerRef = useRef<NodeJS.Timeout | null>(null);
  // Référence pour le timer de fermeture automatique
  const closeTimerRef = useRef<NodeJS.Timeout | null>(null);

  // Détecter mobile/desktop
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    checkMobile(); // Vérification initiale
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Initialisation des événements et ouverture de la modale
  useEffect(() => {
    // S'assurer que nous sommes côté client
    if (typeof window === "undefined") return;

    // Vérification si la modal a déjà été affichée durant cette session
    const hasSeenModal = sessionStorage.getItem("hasSeenEventModal");

    if (!hasSeenModal) {
      const events = getCurrentEvents();
      if (events.length > 0) {
        // Formater tous les événements
        const formattedEvents = events.map((event) => ({
          ...event,
          period: formatEventPeriod(event.startDate, event.endDate),
        }));

        setCurrentEvents(formattedEvents);

        // Délai pour une meilleure expérience utilisateur
        const timer = setTimeout(() => {
          setIsOpen(true);
          // Marquer la modal comme vue pour cette session
          sessionStorage.setItem("hasSeenEventModal", "true");
          // Empêcher le défilement
          document.body.style.overflow = "hidden";
        }, 1500);

        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, []);

  // Gestion des timers (défilement et fermeture automatique)
  useEffect(() => {
    if (!isOpen || currentEvents.length === 0) return;

    // Nettoyer les timers existants
    if (slideTimerRef.current) clearInterval(slideTimerRef.current);
    if (closeTimerRef.current) clearTimeout(closeTimerRef.current);

    // Cas d'un seul événement : fermeture après 10 secondes
    if (currentEvents.length === 1) {
      closeTimerRef.current = setTimeout(() => {
        handleClose();
      }, 10000);
    }
    // Cas de plusieurs événements
    else {
      // Sur mobile ET desktop : défilement automatique
      slideTimerRef.current = setInterval(() => {
        setActiveEventIndex((prevIndex) => {
          // Si c'est le dernier événement, fermer la modale
          if (prevIndex === currentEvents.length - 1) {
            setTimeout(() => {
              handleClose();
            }, 100);
            return prevIndex;
          }
          // Sinon passer à l'événement suivant
          return prevIndex + 1;
        });
      }, 8000);
    }

    // Nettoyage des timers à la fermeture
    return () => {
      if (slideTimerRef.current) clearInterval(slideTimerRef.current);
      if (closeTimerRef.current) clearTimeout(closeTimerRef.current);
    };
  }, [isOpen, currentEvents.length, isMobile]);

  const handleClose = () => {
    setIsOpen(false);
    // Réactiver le défilement
    document.body.style.overflow = "unset";
    // Nettoyer les timers
    if (slideTimerRef.current) clearInterval(slideTimerRef.current);
    if (closeTimerRef.current) clearTimeout(closeTimerRef.current);
    // Attendre la fin de l'animation avant d'appeler onClose
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const openPromoModal = (event: any) => {
    setSelectedEventForPromo(event);
    setIsPromoModalOpen(true);
  };

  // Si pas d'événements ou modal déjà vue
  if (currentEvents.length === 0 || !isOpen) return null;

  // Rendu pour un seul événement
  if (currentEvents.length === 1) {
    const event = currentEvents[0];
    const hasPromoCode = event.promoCode && event.promoCode.trim() !== "";

    return (
      <AnimatePresence>
        {isOpen && (
          <div className="fixed inset-0 z-[51] flex items-center justify-center">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute inset-0 bg-black/80"
              onClick={handleClose}
            />

            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ type: "spring", damping: 20, stiffness: 300 }}
              className="relative z-10 w-[90%] max-w-4xl bg-white shadow-2xl"
            >
              <button
                onClick={handleClose}
                className="absolute right-2 top-2 z-20 hidden p-1 text-3xl hover:text-white sm:right-4 sm:top-2 md:block md:text-gold"
                aria-label="Fermer"
              >
                ×
              </button>

              <div className="grid md:grid-cols-2">
                <div className="relative h-64 w-full sm:h-72 md:h-auto">
                  <Image
                    src={event.image}
                    alt={event.title}
                    fill
                    sizes="(max-width: 768px) 100vw, 50vw"
                    className="object-cover object-top"
                    priority
                  />
                </div>

                <div className="flex flex-col justify-between bg-primary p-4 text-white sm:p-6 md:p-8">
                  <div className="flex flex-col gap-4">
                    <div>
                      <p className="font-cormorant text-base uppercase tracking-wide sm:text-xl">
                        Événement du mois
                      </p>
                      <h2 className="mt-1 text-2xl font-medium sm:mt-2 sm:text-3xl md:text-4xl">
                        {event.title}
                      </h2>
                      <p className="mt-1 text-xs text-white/80 sm:text-sm">
                        {event.period}
                      </p>
                    </div>

                    <p className="text-sm leading-relaxed sm:text-base">
                      {event.description}
                    </p>

                    <div>
                      <p className="mb-1 text-xs font-medium uppercase tracking-wider sm:mb-2 sm:text-sm">
                        Salons concernés
                      </p>
                      <div className="flex flex-wrap gap-2">
                        {event.salons.map((salon: string, idx: number) => (
                          <Link
                            key={idx}
                            href={formatSalonUrl(salon)}
                            className="text-xs underline transition-colors hover:text-gold sm:text-sm"
                          >
                            {salon}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 flex flex-wrap gap-6 sm:mt-6 md:mt-8">
                    {hasPromoCode ? (
                      <button
                        className="group relative inline-block pb-[2px] text-sm sm:text-base"
                        onClick={() => openPromoModal(event)}
                      >
                        <span>Obtenir mon code promo</span>
                        <div className="absolute bottom-0 left-0 h-[1px] w-full bg-white">
                          <div className="h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></div>
                        </div>
                      </button>
                    ) : (
                      <Link
                        href="/reservation"
                        className="group relative inline-block pb-[2px] text-sm sm:text-base"
                        onClick={handleClose}
                      >
                        <span>Prendre rendez-vous</span>
                        <div className="absolute bottom-0 left-0 h-[1px] w-full bg-white">
                          <div className="h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></div>
                        </div>
                      </Link>
                    )}
                    <button
                      className="group relative block inline-block pb-[2px] text-sm sm:text-base md:hidden"
                      onClick={handleClose}
                    >
                      <span>Fermer l&apos;offre</span>
                      <div className="absolute bottom-0 left-0 h-[1px] w-full bg-white">
                        <div className="h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>

              {/* Barre de progression pour un événement */}
              <div className="w-full">
                <div className="h-1 w-full bg-white/20">
                  <motion.div
                    className="h-full bg-gold"
                    initial={{ width: "0%" }}
                    animate={{ width: "100%" }}
                    transition={{
                      duration: 10, // 10 secondes pour un événement
                      ease: "linear",
                    }}
                  />
                </div>
              </div>
            </motion.div>
          </div>
        )}

        {/* Modal pour le code promo */}
        {isPromoModalOpen && selectedEventForPromo && (
          <PromoCodeModal
            isOpen={isPromoModalOpen}
            closeModal={() => setIsPromoModalOpen(false)}
            event={selectedEventForPromo}
          />
        )}
      </AnimatePresence>
    );
  }

  // Rendu pour plusieurs événements
  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-[51] flex items-center justify-center">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 bg-black/80"
            onClick={handleClose}
          />

          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ type: "spring", damping: 20, stiffness: 300 }}
            className="relative z-10 w-[90%] max-w-4xl bg-white shadow-2xl"
          >
            <button
              onClick={handleClose}
              className="absolute right-2 top-2 z-20 hidden p-1 text-3xl hover:text-white sm:right-4 sm:top-2 md:block md:text-gold"
              aria-label="Fermer"
            >
              ×
            </button>

            <div className="relative">
              {currentEvents.map((event, index) => {
                const hasPromoCode =
                  event.promoCode && event.promoCode.trim() !== "";

                // N'afficher que l'événement actif
                if (index !== activeEventIndex) return null;

                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
                  >
                    <div className="grid md:grid-cols-2">
                      <div className="relative h-64 w-full sm:h-72 md:h-auto">
                        <Image
                          src={event.image}
                          alt={event.title}
                          fill
                          sizes="(max-width: 768px) 100vw, 50vw"
                          className="object-cover object-top"
                          priority
                        />
                      </div>

                      <div className="flex flex-col justify-between bg-primary p-4 text-white sm:p-6 md:p-8">
                        <div className="flex flex-col gap-4">
                          <div>
                            <p className="font-cormorant text-base uppercase tracking-wide sm:text-xl">
                              Événement du mois
                            </p>
                            <h2 className="mt-1 text-2xl font-medium sm:mt-2 sm:text-3xl md:text-4xl">
                              {event.title}
                            </h2>
                            <p className="mt-1 text-xs text-white/80 sm:text-sm">
                              {event.period}
                            </p>
                          </div>

                          <p className="text-sm leading-relaxed sm:text-base">
                            {event.description}
                          </p>

                          <div>
                            <p className="mb-1 text-xs font-medium uppercase tracking-wider sm:mb-2 sm:text-sm">
                              Salons concernés
                            </p>
                            <div className="flex flex-wrap gap-2">
                              {event.salons.map(
                                (salon: string, idx: number) => (
                                  <Link
                                    key={idx}
                                    href={formatSalonUrl(salon)}
                                    className="text-xs underline transition-colors hover:text-gold sm:text-sm"
                                  >
                                    {salon}
                                  </Link>
                                ),
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="mt-4 flex flex-wrap gap-6 sm:mt-6 md:mt-8">
                          {hasPromoCode ? (
                            <button
                              className="group relative inline-block pb-[2px] text-sm sm:text-base"
                              onClick={() => openPromoModal(event)}
                            >
                              <span>Obtenir mon code promo</span>
                              <div className="absolute bottom-0 left-0 h-[1px] w-full bg-white">
                                <div className="h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></div>
                              </div>
                            </button>
                          ) : (
                            <Link
                              href="/reservation"
                              className="group relative inline-block pb-[2px] text-sm sm:text-base"
                              onClick={handleClose}
                            >
                              <span>Prendre rendez-vous</span>
                              <div className="absolute bottom-0 left-0 h-[1px] w-full bg-white">
                                <div className="h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></div>
                              </div>
                            </Link>
                          )}
                          <button
                            className="group relative block inline-block pb-[2px] text-sm sm:text-base md:hidden"
                            onClick={handleClose}
                          >
                            <span>Fermer l&apos;offre</span>
                            <div className="absolute bottom-0 left-0 h-[1px] w-full bg-white">
                              <div className="h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                );
              })}

              {/* Barre de progression pour plusieurs événements */}
              <div className="w-full">
                <div className="h-1 w-full bg-white/20">
                  <motion.div
                    className="h-full bg-gold"
                    initial={{ width: "0%" }}
                    animate={{ width: "100%" }}
                    transition={{
                      duration: 8,
                      ease: "linear",
                      repeat: 0,
                    }}
                    key={activeEventIndex}
                  />
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}

      {/* Modal pour le code promo */}
      {isPromoModalOpen && selectedEventForPromo && (
        <PromoCodeModal
          isOpen={isPromoModalOpen}
          closeModal={() => setIsPromoModalOpen(false)}
          event={selectedEventForPromo}
        />
      )}
    </AnimatePresence>
  );
};
