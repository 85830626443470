"use client";

import { useEffect, useState, useRef } from "react";
import Link from "next/link";
import { Icon } from "@iconify/react";
import { motion, AnimatePresence } from "framer-motion";

interface BannerItem {
  id: number;
  content: React.ReactNode;
}

export const HeroBanner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [progress, setProgress] = useState(0);
  const elapsedRef = useRef(0);
  const lastTimeRef = useRef<number | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const heroBottom = heroSection.getBoundingClientRect().bottom;
        setIsVisible(heroBottom > 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const bannerItems: BannerItem[] = [
    {
      id: 1,
      content: (
        <Link
          href="/evenements"
          className="group relative text-sm text-white underline decoration-white/20 decoration-1 underline-offset-4 transition-all duration-300 hover:decoration-gold ph:text-base"
        >
          Évènement exclusif : découvrez-le ici
        </Link>
      ),
    },
    {
      id: 2,
      content: (
        <Link
          href="/reservation"
          className="group relative text-sm text-white underline decoration-white/20 decoration-1 underline-offset-4 transition-all duration-300 hover:decoration-gold ph:text-base"
        >
          Prendre rendez-vous
        </Link>
      ),
    },
    {
      id: 3,
      content: (
        <Link
          href="/prestations/coloration-vegetale"
          className="group text-sm text-white ph:text-base"
        >
          <span className="relative inline-block">
            Découvrez la coloration végétale
            <span className="absolute bottom-0 left-0 h-[1px] w-full bg-white/20">
              <span className="absolute bottom-0 left-0 h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></span>
            </span>
          </span>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    let animationFrame: number;

    const animate = (timestamp: number) => {
      if (!lastTimeRef.current) {
        lastTimeRef.current = timestamp;
      }

      const deltaTime = timestamp - lastTimeRef.current;
      lastTimeRef.current = timestamp;

      if (isPlaying) {
        elapsedRef.current += deltaTime;
      }

      const newProgress = (elapsedRef.current % 6000) / 6000;

      if (elapsedRef.current >= 6000) {
        elapsedRef.current = elapsedRef.current % 6000;
        setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerItems.length);
      }

      setProgress(newProgress);
      animationFrame = requestAnimationFrame(animate);
    };

    animationFrame = requestAnimationFrame(animate);

    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [isPlaying, bannerItems.length]);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  if (!isVisible) return null;

  return (
    <div className="absolute left-0 top-0 z-50 flex h-[60px] w-full items-center bg-primary px-4 ph:h-[40px] ph:px-8">
      <div className="flex items-center gap-2 ph:gap-4">
        <div className="relative h-3 w-3 ph:h-4 ph:w-4">
          <svg className="absolute h-full w-full -rotate-90">
            <circle
              cx="6"
              cy="6"
              r="5"
              fill="none"
              stroke="rgba(255,255,255,0.2)"
              strokeWidth="1.5"
              className="ph:hidden"
            />
            <circle
              cx="6"
              cy="6"
              r="5"
              fill="none"
              stroke="white"
              strokeWidth="1.5"
              strokeDasharray={31.4}
              strokeDashoffset={31.4 * (1 - progress)}
              className="ph:hidden"
              style={{
                transition: isPlaying ? "none" : "stroke-dashoffset 0.2s ease",
              }}
            />
            <circle
              cx="8"
              cy="8"
              r="7"
              fill="none"
              stroke="rgba(255,255,255,0.2)"
              strokeWidth="2"
              className="hidden ph:block"
            />
            <circle
              cx="8"
              cy="8"
              r="7"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeDasharray={44}
              strokeDashoffset={44 * (1 - progress)}
              className="hidden ph:block"
              style={{
                transition: isPlaying ? "none" : "stroke-dashoffset 0.2s ease",
              }}
            />
          </svg>
        </div>
        <span className="text-xs text-white/80 ph:text-sm">{`${currentIndex + 1}/${bannerItems.length}`}</span>
      </div>

      <div className="mx-4 flex-1 overflow-hidden text-center">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentIndex}
            initial={{ y: 15, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -15, opacity: 0 }}
            transition={{
              duration: 0.3,
              ease: "easeOut",
            }}
          >
            {bannerItems[currentIndex].content}
          </motion.div>
        </AnimatePresence>
      </div>

      <button
        onClick={togglePlayPause}
        className="hidden text-white transition-colors hover:text-gold ph:block"
      >
        {isPlaying ? (
          <Icon icon="material-symbols:pause" width="16" />
        ) : (
          <Icon icon="material-symbols:play-arrow" width="16" />
        )}
      </button>
    </div>
  );
};
