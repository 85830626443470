import evaberzal from "@/assets/images/reviews/eva-berzal.png";
import anaispriccaz from "@/assets/images/reviews/anais-priccaz.png";
import virginie from "@/assets/images/reviews/virginie.png";
import celineflouret from "@/assets/images/reviews/celine-flouret.png";
import raphaellaurent from "@/assets/images/reviews/raphael-laurent.png";
import corinnelouis from "@/assets/images/reviews/corinne-louis.png";
import perrinaxelle from "@/assets/images/reviews/perrin-axelle.png";
import huguettedalle from "@/assets/images/reviews/huguette-dalle.png";

export interface ReviewData {
  id: string;
  name: string;
  imagePath: string;
  review: string;
  postedAt: string;
}

export const reviewsData: ReviewData[] = [
  {
    id: "anais-priccaz",
    name: "Anais Priccaz",
    imagePath: anaispriccaz.src,
    review:
      "Toujours satisfaite comme depuis que je viens ! Accueil chaleureux, avec la proposition d’un petit café/thé, les coiffeurs (ses) savent vous mettre à l’aise ! L’écoute, les conseils des coiffeurs ainsi que leur professionnalisme sont leur priorités Ce n’est pas la première fois dans ce salon et ce ne sera pas la dernière Je vous recommande vivement ce salon de coiffure !! Merci à toute l’équipe Grey 😊",
    postedAt: "il y a 1 mois",
  },
  {
    id: "eva-berzal",
    name: "Éva Berzal",
    imagePath: evaberzal.src,
    review:
      "Je sors tout juste du salon ! Couleur et massage balinais 😍 Un bien fou! Merci à toutes les deux.",
    postedAt: "il y a 3 mois",
  },
  {
    id: "perrin-axelle",
    name: "Perrin Axelle",
    imagePath: perrinaxelle.src,
    review:
      "Ravie de ce moment, Alexandra à beaucoup de talents, nouvelle couleur, nouvelle coupe, elle a trouvé ce dont j avais besoin, l'équipe est accueillante, souriante, au petit soin pour tous Merci",
    postedAt: "il y a 3 semaines",
  },
  {
    id: "virginie",
    name: "Virginie",
    imagePath: virginie.src,
    review:
      "Une jolie découverte. Déjà pour commencer, personnel agréable, souriant et salon propre. J’y ai effectué une coloration et on m’a proposé un rituel balinais. Mais wahou, j’ai été conquise par ce massage, pas comme les autres du cuir chevelure qui prends aussi la nuque et une partie du visage (mesdames, maquillage léger ce jour là 😁). C’est vraiment un moment de bien être à part entière. Alors merci pour ce moment de détente, de convivialité et de beauté.",
    postedAt: "il y a 1 mois",
  },
  {
    id: "huguette-dalle",
    name: "Huguette Dalle",
    imagePath: huguettedalle.src,
    review:
      "Je suis régulièrement coiffée par Marc; il est toujours attentif à ce que je lui demande et ma coupe est parfaite.Par ailleurs un très bon contact ,c'est une détente pour moi de venir faire ma coupe car il discute avec ses clients. Le salon est aéré, agréable, on nous offre café ou thé ,c'est chaleureux.Je recommande ce salon.",
    postedAt: "il y a 1 mois",
  },
  {
    id: "celine-flouret",
    name: "Céline Flouret",
    imagePath: celineflouret.src,
    review:
      "Toujours satisfaite comme depuis que je viens ! Accueil chaleureux, avec la proposition d’un petit café/thé, les coiffeurs (ses) savent vous mettre à l’aise ! L’écoute, les conseils des coiffeurs ainsi que leur professionnalisme sont leur priorités Ce n’est pas la première fois dans ce salon et ce ne sera pas la dernière Je vous recommande vivement ce salon de coiffure !! Merci à toute l’équipe Grey 😊",
    postedAt: "il y a 4 mois",
  },
  {
    id: "raphael-laurent",
    name: "Raphael Laurent",
    imagePath: raphaellaurent.src,
    review:
      "Toujours au top ce salon. Équipe parfaite, prestation parfaite. 💪",
    postedAt: "il y a 5 mois",
  },
  {
    id: "corinne-louis",
    name: "Corinne Louis",
    imagePath: corinnelouis.src,
    review:
      "Cela fait plus de 10 ans que je vais me faire couper les cheveux dans ce salon ainsi que ma fille. J'ai toujours été satisfaite et pourtant je ne suis pas toujours coupée par la même personne et je fais des coupes très courtes ce qui n'est pas simple . L'équipe est sympa et fait de son mieux pour que l'on se sente bien et que l'on sorte avec le sourire, ce qui est toujours mon cas! Merci l'équipe et à bientôt !",
    postedAt: "il y a 3 mois",
  },
];
