"use client";

import React from "react";
import { MultipleEventsLayout } from "@/components/sections/monthly-event/MultipleEventsLayout";
import { SingleEventLayout } from "@/components/sections/monthly-event/SingleEventLayout";

// Import depuis le fichier statique (comme dans page.tsx)
import {
  getCurrentEvents,
  formatEventPeriod,
} from "@/(global)/evenements/data/events";

type FormattedEvent = {
  title: string;
  description: string;
  image: string;
  period: string;
  salons: string[];
  promoCode: string;
};

export function MonthlyEventSection() {
  // Récupération et formatage des événements directement (comme dans page.tsx)
  const currentEvents = getCurrentEvents();
  const events: FormattedEvent[] = currentEvents.map((event) => ({
    title: event.title,
    description: event.description,
    image: event.image,
    period: formatEventPeriod(event.startDate, event.endDate),
    salons: event.salons,
    promoCode: event.promoCode || "",
  }));

  // Si aucun événement, ne rien afficher
  if (!events.length) {
    return null;
  }

  // Affichage simple ou multiple en fonction du nombre d'événements
  return events.length === 1 ? (
    <SingleEventLayout event={events[0]} />
  ) : (
    <MultipleEventsLayout events={events} />
  );
}
